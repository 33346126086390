import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/warehouse',
  component: Layout,
  redirect: '/warehouse/summary',
  meta: {
    title: '库存管理',
    icon: 'tdesign:houses'
  },
  children: [
    {
      path: 'summary',
      name: 'warehouseSummary',
      component: () => import('@/views/warehouse/summary.vue'),
      meta: {
        title: '汇总库存查询',
        icon: 'tdesign:data-base'
      }
    },
    {
      path: 'signal',
      name: 'warehouseSignal',
      component: () => import('@/views/warehouse/signal.vue'),
      meta: {
        title: '分仓库存查询',
        icon: 'tdesign:store'
      }
    }
  ]
}

export default routes
