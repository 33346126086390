import { defaultsDeep } from 'lodash-es'
import type { RecursiveRequired, Settings } from '#/global'
import settingsDefault from '@/settings.default'

const globalSettings: Settings.all = {
  // 请在此处编写或粘贴配置代码
  app: {
    enableAppSetting: false,
    colorScheme: 'light',
    enablePermission: true,
    enableProgress: true,
    enableDynamicTitle: false,
    routeBaseOn: 'frontend',
  },
  home: {
    enable: false,
    title: '首页',
  },
  layout: {
    enableMobileAdaptation: true,
  },
  menu: {
    baseOn: 'frontend',
    menuMode: 'single',
    switchMainMenuAndPageJump: false,
    subMenuUniqueOpened: true,
    subMenuCollapse: false,
    enableSubMenuCollapseButton: true,
    enableHotkeys: true,
  },
  topbar: {
    mode: 'fixed',
  },
  toolbar: {
    enableFullscreen: false,
    enablePageReload: true,
    enableColorScheme: true,
  },
  breadcrumb: {
    enable: true,
  },
  mainPage: {
    enableHotkeys: true,
  },
  navSearch: {
    enable: true,
    enableHotkeys: true,
  },
  copyright: {
    enable: (import.meta.env.VITE_COPYRIGHT_ENABLE == 'true'),
    dates: import.meta.env?.VITE_COPYRIGHT_DATE ?? '',
    company: import.meta.env?.VITE_COPYRIGHT_COMPANY ?? '',
    website: import.meta.env?.VITE_COPYRIGHT_WEBSITE ?? '',
    beian: import.meta.env?.VITE_COPYRIGHT_BEIAN ?? '',
  },
}

export default defaultsDeep(globalSettings, settingsDefault) as RecursiveRequired<Settings.all>
