import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/purchase',
  component: Layout,
  redirect: '/purchase/index',
  meta: {
    title: '采购管理',
    icon: 'tdesign:shop'
  },
  children: [
    {
      path: 'index',
      name: 'purchaseIndex',
      component: () => import('@/views/purchase/query.vue'),
      meta: {
        title: "采购查询",
        icon: "tdesign:cart"
      }
    }
  ]
}

export default routes
