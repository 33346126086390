type mocktype = {
  [key: string]: string
}

// apifox 下的接口地址
const mocklist:mocktype = {
    "announce/list": "128718709",
    "customer/info": "128728629",
    "supply/info": "128728484",
    "goods/info": "128727355",
    "goods/all": "128727593",
    "goods/detail": "128760752",
    "order/buys": "128725587",
    "invoice/list": "128729652",
    "user/login": "128741350",
    "user/permission": "128731208",
    "warehouse/summary": "128948688",
    "warehouse/signal": "128987280",
    "purchase/query": "129061302"
}

export default {
    token: "WdRdyaJpWXA4PSKyIhY6AKAYQJb2ygXr",
    convert: (path: string) => {
        return mocklist[path] ?? path
    }
};
