import type { RouteRecordRaw } from 'vue-router'

function Layout() {
  return import('@/layouts/index.vue')
}

const routes: RouteRecordRaw = {
  path: '/base',
  component: Layout,
  redirect: '/base/announcement',
  meta: {
    title: '基础功能',
    icon: 'tdesign:command',
  },
  children: [
    {
      path: 'announcement',
      name: 'baseAnnouncement',
      component: () => import('@/views/base/announcement.vue'),
      meta: {
        title: '系统公告',
        icon: 'tdesign:tips',
      },
    },
    {
      path: 'deliveryquery',
      name: 'baseDeliveryQuery',
      component: () => import('@/views/base/delivery_query.vue'),
      meta: {
        title: '发货计划查询',
        icon: 'tdesign:calendar-event',
      },
    },
    {
      path: 'deliverycreate',
      name: 'baseDeliveryCreate',
      component: () => import('@/views/base/delivery_create.vue'),
      meta: {
        title: '发货计划创建',
        icon: 'tdesign:chart-add',
      },
    },
    {
      path: 'paymentquery',
      name: 'basePaymentQuery',
      component: () => import('@/views/base/payment_query.vue'),
      meta: {
        title: '付款查询',
        icon: 'tdesign:money',
      },
    },
  ],
}

export default routes
