import axios from 'axios'
import apifox from './apifox'

// import qs from 'qs'
import Message from 'vue-m-message'
import useUserStore from '@/store/modules/user'

const api = axios.create({
  baseURL: (import.meta.env.DEV && import.meta.env.VITE_OPEN_PROXY === 'true') ? '/proxy/' : import.meta.env.VITE_APP_API_BASEURL,
  timeout: 1000 * 60,
  responseType: 'json',
})


declare module 'axios' {
  export interface SuccessResponse<T> {
    status: number
    msg?: string
    url?: string
    data?: T
    [keys: string]: any
  }
  export interface ErrorResponse<T> {
    msg: string
    error_code: number
    request_url?: string
    url?: string
  }
}

api.interceptors.request.use(
  (request) => {
    // 全局拦截请求发送前提交的参数
    const userStore = useUserStore()
    const ENV_MODE = import.meta.env.MODE

    if (ENV_MODE == "test") {
      // 根据 APIFOX 替换地址
      if (!request.params) {
        Object.assign(request, {params: {apifoxToken: apifox.token}})
      } else {
        Object.assign(request.params, {apifoxToken: apifox.token})
      }

      if (typeof request.url != "undefined") {
        request.url = apifox.convert(request.url)
      }
    } else if (ENV_MODE == "development") {
      request.baseURL = '/mock/'
    }
    // 设置请求头
    if (request.headers) {
      if (userStore.isLogin) {
        request.headers.token = userStore.token
      }
    }
    // 是否将 POST 请求参数进行字符串化处理
    if (request.method === 'post') {
      // request.data = qs.stringify(request.data, {
      //   arrayFormat: 'brackets',
      // })
    }
    return request
  },
)

api.interceptors.response.use(
  (response) => {
    /**
     * 全局拦截请求发送后返回的数据，如果数据有报错则在这做全局的错误提示
     * 假设返回数据格式为：{ status: 1, error: '', data: '' }
     * 规则是当 status 为 1 时表示请求成功，为 0 时表示接口需要登录或者登录状态失效，需要重新登录
     * 请求出错时 error 会返回错误信息
     */
    if (response.status === 200) {
      if (response.data.status !== 1) {
        // 错误提示
        Message.error(response.data.msg, {
          zIndex: 2000,
        })
        return Promise.reject(response.data)
      }
    }
    else if (response.status !== 200) {
      switch (response.status) {
        case 400:
          Message.error(response.data.msg, { duration: 5000 })
          break
        default:
          Message.error('发生未知错误', { duration: 5000 })
          break
      }

      return Promise.reject(response.data)
    }
    else {
      useUserStore().logout()
    }

    return Promise.resolve(response.data)
  },
  (error) => {
    let message = error.message
    if (message === 'Network Error') {
      message = '后端网络故障'
    }
    else if (message.includes('timeout')) {
      message = '接口请求超时'
    }
    else if (message.includes('Request failed with status code')) {
      message = `接口${message.substr(message.length - 3)}异常`
    }
    Message.error(message, {
      zIndex: 2000,
    })
    return Promise.reject(error)
  },
)

export default api
